<template>
  <div id="site-list">
    <div class="site-list-inner">

      <div class="site" v-for="site in sites" :key="site.url">
        <div class="content" v-longpress="showMobileDelete" @click="openSite(site)">
          <div class="ico" @click="(e)=>{e.preventDefault()}">
            <img :src="site.ico" alt="ico" @error="handelIcoError($event)">
            <span class="ico-success">{{site.textIco}}</span>
          </div>
          <div class="name">
            {{site.name}}
          </div>
        </div>

        <div class="mobile-delete-hide">
          <div class="mobile-delete-bg">
            <div class="mobile-delete-inner" @click="deleteSite(site)">
              <svg class="icon">
                <use xlink:href="#icon-close"></use>
              </svg>
            </div>
          </div>
        </div>

        <div class="pc-delete" @click="deleteSite(site)">
          <svg class="icon">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>

      </div>

      <div class="site add-button" @click="addSite" :class="{'active': eventBus.addFormOpen}">
        <svg class="icon" aria-hidden="true" :class="{'active': eventBus.addFormOpen}">
          <use xlink:href="#icon-add"></use>
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
  import '../assets/icon'

  export default {
    name: "SiteList",
    inject: ['eventBus'],
    data() {
      return {
        sites: [
        {
            name: '鸿蒙论坛',
            url: 'https://developer.huawei.com/consumer/cn/forum/topic/0204156248509920001?fid=0109140870620153026',
            ico: 'https://newsimg.dangbei.net/ueditor/php/upload/image/20190626/1561536812521640.jpg!0',
            textIco: 'H'
          },
          {
            name: '鸿蒙笔记',
            url: 'https://harmony.pages.etsuyou.cn/',
            ico: 'https://developer.huawei.com/allianceCmsResource/resource/HUAWEI_Developer_VUE/images/0417/home-new-15.png',
            textIco: 'H'
          },
          {
            name: '博客',
            url: 'https://blog.pages.etsuyou.cn/',
            ico: 'https://s2.loli.net/2024/06/26/8bfW2twZPToJCOF.png',
            textIco: 'B'
          },
          {
            name: '前端笔记',
            url: 'https://interview.pages.etsuyou.cn/',
            ico: 'https://i.pinimg.com/564x/e2/7a/70/e27a70daecbe469ca713adf592e5f31e.jpg',
            textIco: 'I'
          },

          // {
          //   name: 'Bilibili',
          //   url: 'https://space.bilibili.com/13716124',
          //   ico: 'https://www.bilibili.com/favicon.ico',
          //   textIco: 'B'
          // },
          
          {
            name: 'Github',
            url: 'https://github.com/etsuyou',
            ico: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/GitHub_Invertocat_Logo.svg/270px-GitHub_Invertocat_Logo.svg.png',
            textIco: 'B'
          },
          {
            name: 'Gitee',
            url: 'https://gitee.com/etsuyou',
            ico: 'https://pic1.zhimg.com/v2-77b1a31dc62d9e00abd0ea6cdaba5c83_xl.jpg?source=32738c0c',
            textIco: 'B'
          },
          // 
          {
            name: 'NPM主页',
            url: 'https://www.npmjs.com/package/@etsuyou/upload-image',
            ico: '',
            textIco: 'N'
          },
          {
            name: 'Ele+',
            url: 'https://cn.element-plus.org/zh-CN/guide/design.html',
            ico: 'https://avatars.githubusercontent.com/u/68583457?v=4',
            textIco: 'E'
          },

          {
            name: 'Vant4',
            url: 'https://vant-ui.github.io/vant/#/zh-CN',
            ico: 'https://fastly.jsdelivr.net/npm/@vant/assets/logo.png',
            textIco: 'V'
          },
          // 
          {
            name: '微信小程序',
            url: 'https://developers.weixin.qq.com/miniprogram/dev/framework/',
            ico: 'https://img.ixintu.com/upload/jpg/20210522/bc2ff93cd2ea37c8afd8bd164ce565b9_19308_512_512.jpg!con',
            textIco: 'V'
          },

          {
            name: 'Vue3',
            url: 'https://cn.vuejs.org/',
            ico: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEHE-tKgTaktgfR6R6xPUzBy5Am6ZLbbKwpg&s',
            textIco: 'V'
          },
          
          {
            name: 'iconfont',
            url: 'https://www.iconfont.cn/',
            ico: 'https://urlsnwww-1257117300.file.myqcloud.com/wp-content/uploads/1666780556966.png',
            textIco: 'I'
          },

        ],
        mobileDeleteShow: false,

      }
    },
    methods: {
      showMobileDelete(e, el) {
        this.vibration(20)
        el.nextSibling.classList.remove('mobile-delete-hide')
        el.nextSibling.classList.add('mobile-delete-show')
        document.addEventListener('click', () => {
          this.hideMobileDelete(el)
        })
      },
      hideMobileDelete(el) {
        el.nextSibling.classList.add('mobile-delete-hide')
        el.nextSibling.classList.remove('mobile-delete-show')
      },
      vibration(duration) {
        window.navigator.vibrate = window.navigator.vibrate
          || window.navigator.webkitVibrate
          || window.navigator.mozVibrate
          || window.navigator.msVibrate
        if (window.navigator.vibrate) {
          window.navigator.vibrate(duration)
        }
      },
      addSite() {
        this.eventBus.addFormOpen = true
      },
      deleteSite(site) {
        this.sites.splice(this.sites.indexOf(site), 1)
        localStorage.setItem('sites', JSON.stringify(this.sites))
      },
      openSite(site) {
        window.open(site.url, '_self')
      },
      handelIcoError(event) {
        event.target.classList.add('ico-error')
        event.target.nextSibling.classList.remove('ico-success')
        event.target.nextSibling.classList.add('ico-error')
      }
    },
    created() {
      if (localStorage.getItem('sites')) {
        this.sites = JSON.parse(localStorage.getItem('sites'))
      }
      this.eventBus.$on('addSite', ({name, url, ico, textIco}) => {
        this.sites.push({name, url, ico, textIco, icoError: false})
        localStorage.setItem('sites', JSON.stringify(this.sites))
      })
    }
  }
</script>

<style lang="scss" scoped>
  @keyframes mobile-delete-bg-show {
    0% {width: 0;height: 0;}
    100% {width: 142%;height: 142%;}
  }
  @keyframes mobile-delete-inner-show {
    0% {width: 0;height: 0;font-size: 0;}
    100% {width: 50px;height: 50px;font-size: 30px;}
  }
  #site-list {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    // 添加上下滚动,并且父元素要有固定高度
    padding: 10px;
    height: 70vh;
    overflow-y: auto;
    /* 隐藏滚动条 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    #site-list::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }

    @media(min-width: 576px) {
      max-width: 4900px;
    }
    @media(min-width: 993px) {
      max-width: 940px;
    }
    .site-list-inner {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      flex-wrap: wrap;
      justify-content: space-evenly;

      /* 防止手机下面显示不全 */
      padding-bottom: 100px;

      @media(min-width: 576px) {
        margin-right: -20px;
        margin-left: 0;
        justify-content: flex-start;
      }
      .site {
        width: 100px;
        height: 100px;
        margin-left: 7px;margin-right: 7px;margin-bottom: 20px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 1px 1px #b9daff;
        position: relative;
        transition: all .2s;
        @media(min-width: 576px) {
          margin-left: 0;
          margin-right: 20px;
        }
        &:hover, &:active {
          box-shadow: 0 1px 3px #b9daff;
          color: #1d78eb;
          .pc-delete {
            opacity: 1;
          }
        }
        .content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 10px 0 10px;
          position: relative;
          cursor: pointer;
          .ico {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 40px;
            border-radius: 30%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              &.ico-error {
                display: none;
              }
            }
            span {
              &.ico-error {
                display: inline-block;
              }
              &.ico-success {
                display: none;
              }
            }
          }
          .name {
            font-size: 14px;
            margin-top: 7px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 70px;
            text-align: center;
            vertical-align: center;
          }
        }
        .pc-delete {
          display: none;
          @media (min-width: 576px) {
            background-color: #ea4335;
            position: absolute;
            right: 0;
            top: 0;
            cursor: default;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: all .3s;
            .icon {
              color: white;
              width: 15px;
              height: 15px;
            }
            &:hover {
              transform: scale(1.1);
              transition: transform .3s;
              cursor: pointer;
              .icon {
                transform: rotateZ(180deg);
                transition: transform .5s;
              }
            }
          }
        }
        .mobile-delete-hide {
          display: none;
        }
        .mobile-delete-show {
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          position: absolute;
          top: -1px;
          left: -1px;
          border-radius: 16px;
          overflow: hidden;
          .mobile-delete-bg {
            background-color: rgba(225, 225, 225, 0.8);
            animation: mobile-delete-bg-show .3s forwards;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            .mobile-delete-inner {
              animation: mobile-delete-inner-show .4s forwards;
              background-color: rgba(255, 62, 63, 0.7);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              border-radius: 50%;
              .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                color: rgba(255, 255, 255, 0.9);
              }
            }
          }
        }
        &.add-button {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 10px 0 10px;
          position: relative;
          transition: all .3s;
          cursor: pointer;
          box-shadow: 0 1px 1px #b9daff;
          &:hover, &.active {
            background-color: #1d78eb;
            box-shadow: 0 1px 3px #b9daff;
            cursor: pointer;
            .icon {
              color: white;
              transform: rotateZ(90deg);
              transition: all .3s;
            }
          }
          .icon {
            width: 50px;
            height: 50px;
            color: #1d78eb;
            transition: transform .4s;
          }
        }
      }
    }
  }
</style>